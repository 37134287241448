import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import FixedImage from "../components/fixedImage";
import Dentist from "../components/dentist";
import Dentists from "../components/dentists";
import WPLink from "../components/wpLink";
import formatPhone from "../helpers/formatPhone";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Hours from "../components/hours";
import styles from "./location.module.scss";
import SidebarReviewRotator from "../components/sidebarReviewRotator";
import prepareSchemaHours from "../helpers/prepareSchemaHours";

export default (props) => {
	const [schemaData, setSchemaData] = useState(null);

	const {
		data: {
			location,
			location: {
				title,
				birdeyeLocation,
				birdeyeLocation: {
					phone: mainPhone,
					location: {
						city,
						address1: addressLine1,
						address2: addressLine2,
						zip,
						state,
						lat,
						lng
					},
					socialProfileURLs: {
						facebookUrl: facebookPageUrl,
						instagramUrl: instagramHandle
					},
					hoursOfOperations,
					timezone
				},
				ACFLocation: {
					bookMyAppointmentLink,
					locationInfo: {
						right: {
							featuredServicesHeading,
							featuredServices,
							allServicesHeading,
							allServices,
							contentHeading,
							locationContent: content,
							introContent: {
								heading: introHeading,
								blurb: introBlurb,
								link: introLink
							},
							patientInfo: {
								heading: patientInfoHeading,
								blurb: patientInfoBlurb,
								paperworkLink
							}
						},
						left: {
							insurancesHeading,
							insurances,
							dentists,
							reviewHeading,
							reviews
						}
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						}
					}
				}

			},
			plaqusterKevin,
			plaqusterJake,
			rightArrowYellowOffsite,
			rightArrowYellow,
			locationsPage,
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			redStar,
			arrowLightGray
		}
	} = props;

	useEffect(() => {
		const locHours = prepareSchemaHours(birdeyeLocation.hoursOfOperations);
		const newSchema = `{
			"@context": "https://schema.org",
			"@type": "Dentist",
			"name": "${birdeyeLocation.name}",
			"url": "${props.location.href}",
			"telephone": "${birdeyeLocation.phone}",
			"address": {
				"@type": "PostalAddress",
				"streetAddress": "${birdeyeLocation.location.address1} ${birdeyeLocation.location.address2}",
				"addressLocality": "${birdeyeLocation.location.city}",
				"addressRegion": "${birdeyeLocation.location.state}",
				"postalCode": "${birdeyeLocation.location.zip}",
				"addressCountry": "US"
			},
			"geo": {
				"@type": "GeoCoordinates",
				"latitude": ${birdeyeLocation.location.lat},
				"longitude": ${birdeyeLocation.location.lng}
			},
			"openingHours": [${locHours}]
		}`;

		setSchemaData(newSchema);
	}, []);


	return (
		<Layout hideCtaButton={true}>
			<Seo post={location} />
			<Helmet>
				{schemaData && <script type="application/ld+json">
					{schemaData}
				</script>}
			</Helmet>
			<div className={`${styles.top} hero`}>
				{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styles.bgdesktop} loading={"eager"} /> }
				{ introBGXL && <FluidImage image={introBGXL} passedClass={styles.bgxl} loading={"eager"} /> }
				{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styles.bgmobile} loading={"eager"} /> }
				{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styles.bgtablet} loading={"eager"} /> }

				<WPLink link={{target: '', title: 'Make an Appointment', url: '/schedule-online/'}} passedClass={styles.mobileapptlink} passedIconClass={styles.icon} />

				<div className={styles.kevin}>
					<FluidImage image={plaqusterKevin} loading={"eager"} fadeIn={false} alternativeText={'Kevin the plaquster, a Leap dental brand character'} />
				</div>
				<div className={styles.jake}>
					<FluidImage image={plaqusterJake} loading={"eager"} fadeIn={false} alternativeText={'Jake the plaquster, a Leap dental brand character'} />
				</div>

				<div className={ ( dentists && dentists.length > 0 ) ? styles.left : styles.rightnodoctor }>
					{ headerLogo && <Link to="/" className={styles.logolink}><FluidImage image={headerLogo} passedClass={styles.logo} loading={"eager"} /></Link>}
					<div className={styles.desktopbreadcrumbs}>{ locationsPage && locationsPage.nodes && locationsPage.nodes.length > 0 && <Link className={styles.loclink} to={locationsPage.nodes[0].link}>Locations</Link>} <span className={styles.locationname} dangerouslySetInnerHTML={{__html: title}}></span></div>
					{ introHeading && <div className={styles.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introBlurb && <p dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></p> }
					{ introLink && <WPLink link={introLink} icon={rightArrowYellow} passedClass={styles.link} passedIconClass={styles.icon} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in" /> }
				</div>
				<div className={styles.right}>
					<div className={styles.locationinfo}>
						<h1 className={styles.title}>{title}</h1>
						{ hoursOfOperations && timezone && <Hours locationHours={hoursOfOperations} utcOffset={timezone} passedStyles={styles} /> } 
						{ addressLine1 && <span className={styles.address}>{addressLine1}</span> }
						{ addressLine2 && <span className={styles.address}>{addressLine2}</span> }
						{(city && state && zip) && <span className={styles.address}>{city}, {state} {zip}</span>}
						{ lat && lng && <a className={styles.directions} href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`} target="_blank" rel="noopener noreferrer">Get Directions <FixedImage image={rightArrowYellowOffsite} passedClass={styles.icon} /></a>}
						{ mainPhone && <a className={styles.phone} href={`tel:${mainPhone}`}>{formatPhone(mainPhone)}</a>}
						{ ( instagramHandle || facebookPageUrl ) && (
							<div className={styles.social}>
								{ facebookPageUrl && (
									<a className={styles.socialitem} href={facebookPageUrl} target="_blank" rel="noopener noreferrer">
										<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
											<g fill="none" fillRule="evenodd">
												<g fill="#70C4EA">
													<g>
														<g>
															<g>
																<path d="M14.172 0H.828C.37 0 0 .37 0 .828v13.344c0 .457.37.828.828.828h7.184V9.191H6.057V6.927h1.955v-1.67c0-1.937 1.183-2.992 2.912-2.992.827 0 1.539.062 1.746.09v2.024l-1.198.001c-.94 0-1.122.447-1.122 1.102v1.445h2.241L12.3 9.191h-1.95V15h3.823c.457 0 .828-.37.828-.828V.828C15 .37 14.63 0 14.172 0" transform="translate(-141 -448) translate(35 45) translate(105 180) translate(1 223)"/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</svg>
									</a>
								)}
								{ instagramHandle && (
									<a className={styles.socialitem} href={instagramHandle} target="_blank" rel="noopener noreferrer">
										<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
											<g fill="none" fillRule="evenodd">
												<g fill="#70C4EA">
													<g>
														<g>
															<g>
																<path d="M79.408.045c-.799.037-1.344.163-1.82.349-.494.191-.912.448-1.33.865-.417.417-.673.835-.864 1.328-.186.477-.312 1.022-.349 1.82-.037.8-.045 1.056-.045 3.093s.008 2.292.045 3.092c.037.799.163 1.344.349 1.82.191.494.447.913.865 1.33.417.416.835.672 1.328.864.478.186 1.022.312 1.82.349.8.036 1.056.045 3.093.045s2.292-.008 3.092-.045c.798-.037 1.344-.163 1.82-.349.494-.192.912-.448 1.329-.865.417-.417.673-.835.865-1.328.186-.477.313-1.022.348-1.82.037-.801.045-1.056.045-3.093s-.008-2.292-.045-3.092c-.036-.799-.162-1.344-.348-1.82-.192-.494-.448-.912-.865-1.33-.417-.417-.835-.673-1.328-.864-.477-.186-1.023-.313-1.82-.349C84.793.01 84.537 0 82.5 0c-2.036 0-2.292.008-3.092.045m.062 13.56c-.731-.033-1.129-.155-1.393-.258-.35-.136-.6-.299-.863-.561-.262-.262-.425-.512-.56-.862-.104-.264-.225-.662-.259-1.393-.036-.79-.043-1.028-.043-3.03 0-2.004.008-2.24.043-3.032.034-.731.156-1.128.258-1.393.137-.35.3-.6.561-.862.263-.263.512-.426.863-.561.264-.103.662-.225 1.393-.258.79-.037 1.028-.044 3.03-.044s2.24.008 3.03.044c.732.033 1.129.156 1.394.258.35.135.6.298.862.56.263.263.425.513.561.863.103.264.225.662.258 1.393.037.791.044 1.028.044 3.031s-.007 2.24-.044 3.031c-.033.731-.155 1.129-.258 1.393-.136.35-.298.6-.56.862-.263.262-.513.425-.863.56-.264.104-.662.226-1.393.259-.79.036-1.028.043-3.031.043s-2.24-.007-3.03-.043m6.134-10.109c0 .497.402.9.9.9.496 0 .9-.402.9-.9 0-.496-.403-.9-.9-.9s-.9.403-.9.9M78.649 7.5c0 2.127 1.724 3.851 3.85 3.851 2.128 0 3.852-1.724 3.852-3.851S84.627 3.649 82.5 3.649 78.649 5.373 78.649 7.5M80 7.5C80 6.12 81.12 5 82.5 5S85 6.12 85 7.5 83.88 10 82.5 10 80 8.88 80 7.5" transform="translate(-216 -448) translate(35 45) translate(105 180) translate(1 223)"/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</svg>
									</a> 
								)}
							</div>
						)}
					</div>
					{ bookMyAppointmentLink && <WPLink link={bookMyAppointmentLink} passedClass={styles.link} /> }
					{  dentists && dentists.length > 0 && (
						<div className={styles.dentistcontainer}>
							{ dentists && dentists.length > 1 && dentists.length > 0 && <Dentists dentists={dentists} arrowLightGray={arrowLightGray} /> }
							{ dentists && dentists.length === 1 && <Dentist dentist={dentists[0]} icon={rightArrowYellow} /> }
						</div>
					)}
					<div className={styles.insurancewrap} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
						<div className={styles.insurance}>
							<span className={styles.heading}>{ insurancesHeading ? insurancesHeading : "We accept the following insurance" }</span>
							<div className={styles.insurancebottom}>
								{ insurances && insurances.map( (insurance, index) => {
									const image = insurance.ACFInsurance.logo;
									return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styles.insuranceimage} /> : null;
								})}
							</div>
						</div>
					</div>
				

					{ reviews && reviewHeading && (
						<div className={styles.reviews}>
							<span className={styles.heading}>{reviewHeading}</span>
							<SidebarReviewRotator reviews={reviews} redStar={redStar} />
						</div>
					)}
				</div>
			</div>
			<div className={styles.bottom}>
				<div className={styles.left}>
					<div className={styles.locationcontent}>
						<div className={styles.contentleft}>
							<h2 className={styles.gettoknow}>{ contentHeading ? contentHeading : "Get to know our clinic." }</h2>
							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
						</div>
					</div>
					{ (patientInfoHeading || patientInfoBlurb || paperworkLink) && (
						<div className={styles.patientinfo}>
							{ patientInfoHeading && <span className={styles.heading}>{patientInfoHeading}</span> }
							{ patientInfoBlurb && <div className={styles.blurb} dangerouslySetInnerHTML={{__html: patientInfoBlurb}}></div> }
							{ paperworkLink && <WPLink link={paperworkLink} passedClass={styles.link}  icon={rightArrowYellow} passedIconClass={styles.icon} /> }
						</div>
					)}
					<div className={styles.locationservices} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
						<div className={styles.left}>
							<h3 className={styles.ftservicesheading}>{ featuredServicesHeading ? featuredServicesHeading : "Featured Services" }</h3>
							<div className={styles.servicescontainer}>
								{ featuredServices && featuredServices.map((service, _index) => {
									return (
										<div key={`ftsrvc-'${service.id}`} className={styles.featuredservice}>
											<Link to={service.link} className={styles.title}><h4 className={styles.titlewrap}>{service.title} <FixedImage image={rightArrowYellow} passedClass={styles.icon} /></h4>
												{ service.ACFService.briefDescription && <p dangerouslySetInnerHTML={{__html: service.ACFService.briefDescription}}></p>}
											</Link>
										</div>
									)
								})}
							</div>
						</div>
						<div className={styles.right}>
							<h3 className={styles.heading}>{ allServicesHeading ? allServicesHeading : "All our services." }</h3>
							<ul className={styles.list}>
								{ allServices && allServices.map((service, index) => {
									return (
										<li key={`lstsrvc-'${service.id}`} className={styles.listitem}>
											<Link to={service.link} className={styles.link}>
												<svg xmlns="http://www.w3.org/2000/svg" className={styles.icon} width="9" height="11" viewBox="0 0 9 11">
													<g fill="none" fillRule="evenodd">
														<g fill="#29abe2" className={styles.iconarrow}>
															<g>
																<g>
																	<g>
																		<path d="M129.638 11.04l-2.44-2.452c-.336-.339-.882-.339-1.218 0-.337.339-.337.887 0 1.226l3.91 3.932c.04.04.085.076.132.108.02.013.04.022.06.033.03.016.058.034.089.047.025.01.05.016.077.024.028.009.055.02.084.025.044.009.089.012.133.013.012 0 .023.004.035.004.013 0 .026-.004.039-.004.043-.002.086-.004.129-.013.03-.006.059-.017.088-.027.025-.007.05-.013.073-.022.033-.014.064-.033.095-.05.018-.01.036-.018.053-.03.048-.032.093-.068.133-.109l3.91-3.931c.169-.17.253-.391.253-.613 0-.222-.084-.444-.253-.613-.336-.339-.882-.339-1.219 0l-2.439 2.453V5.867c0-.479-.386-.867-.862-.867s-.862.388-.862.867v5.174z" transform="translate(-266 -2176) translate(140 1911) translate(0 93) translate(0 168) rotate(-90 130.5 9.5)"/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</svg>
												<span className={styles.title}>{service.title}</span>
											</Link>	
										</li>
									)
								})}
							</ul>
						</div>
					</div>
				</div>
				{/* <div className={ (dentists && dentists.length >= 1) ? styles.right : styles.leftnodentist}>
					<div className={styles.insurance}>
						<span className={styles.heading}>We accept the following insurance.</span>
						<div className={styles.insurancebottom}>
							{ insurances && insurances.map( (insurance, index) => {
								const image = insurance.ACFInsurance.logo;
								return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styles.insuranceimage} /> : null;
							})}
						</div>
					</div>
						</div> */}
			</div>
		</Layout>
	);
};

export const query = graphql`
	query($id: String!) {
		location: wpLocation(id: { eq: $id }) {
			title
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
			birdeyeLocation {
				businessId
				timezone
				hoursOfOperations {
					day
					isOpen
					workingHours {
						startHour
						endHour
					}
				}
				location {
					lat
					lng
					address1
					address2
					city
					state
					zip
				}
				name
				phone
				socialProfileURLs {
					facebookUrl
					instagramUrl
				}
			}
			ACFLocation {
				bookMyAppointmentLink {
					target
					title
					url
				}
				locationInfo {
					left {
					  	dentists {
							... on WpDentist {
						  		id
									ACFDentist {
										headshot {
											localFile {
												childImageSharp {
													fluid {
														...GatsbyImageSharpFluid_withWebp
													}
												}
											}
										}
									}
						 		title
						  		link
							}
					  	}
						insurancesHeading
					  	insurances {
							... on WpInsurance {
						  		id
								title
								ACFInsurance {
									logo {
										altText
										localFile {
											childImageSharp {
												fluid {
													...GatsbyImageSharpFluid_withWebp
												}
											}
											publicURL
                							extension
										}
									}
								}
							}
					  	}
					  	reviewHeading
						reviews {
							... on WpReview {
								id
								title
								ACFReview {
									stars
									location
									blurb
								}
							}
						}
					}
					right {
						allServicesHeading
						allServices {
							... on WpService {
								id
								link
								title
							}
						}
						featuredServicesHeading
						featuredServices {
							... on WpService {
								id
								title
								link
								ACFService {
									briefDescription
									serviceIcon {
										altText
										localFile {
											childImageSharp {
												fluid {
													...GatsbyImageSharpFluid_withWebp
												}
											}
											publicURL
                							extension
										}
									}
								}
							}
						}
						introContent {
							blurb
							heading
							link {
								target
								title
								url
							}
						}
						contentHeading
						locationContent
						patientInfo {
							heading
							blurb
							paperworkLink {
								target
								title
								url
							}
						}
					}
				}
			}
		}
		options: wp {
			headerSection {
				ACFHeaderOptions {
					mainNavigationSettings {
						headerLogo {
							altText
							localFile {
								childImageSharp {
									fluid(maxWidth: 420){
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
                				extension
							}
						}
					}
				}
			}
		}
		plaqusterKevin: file(name: {eq: "kevin"}) {
			childImageSharp {
				fluid(maxWidth: 210){
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		plaqusterJake: file(name: {eq: "jake"}) {
			childImageSharp {
				fluid(maxWidth: 140){
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
			childImageSharp {
			  fixed(width: 10) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		rightArrowYellow: file(name: {eq: "arrow"}) {
			childImageSharp {
			  fixed(width: 9) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		introBGMobile: file(name: {eq: "mask"}) {
			childImageSharp {
			  fluid(maxWidth: 699) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGTablet: file(name: {eq: "mask"}) {
			childImageSharp {
			  fluid(maxWidth: 550) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGDesktop: file(name: {eq: "mask"}) {
			childImageSharp {
			  fluid(maxWidth: 840) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGXL: file(name: {eq: "mask"}) {
			childImageSharp {
			  fluid(maxWidth: 1200) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		locationsPage: allWpPage(filter: {template: {templateName: {eq: "Locations Page"}}}) {
			nodes {
			  link
			}
		}
		arrowBlue: file(name: {eq: "arrow_blue"}) {
			childImageSharp {
			  fixed(width: 9) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		redStar: file(name: {eq: "Star"}) {
			childImageSharp {
			  fixed(width: 20) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		arrowLightGray: file(name: {eq: "arrow_light_gray"}) {
			childImageSharp {
			  fixed(width: 10) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
	}
`
